import React, { useEffect, useState } from "react";
import css from "../../styles/Application.module.scss";
import Button from "../../components/Button";
import TextArea from "../../components/TextArea";
import { useHistory, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {
  DogAd,
  useCreateApplicationMutation,
  useGetUserByEmailQuery,
  useGetUserQuery,
} from "../../api/graphql.gen";

const motivationList = [
  "Selskap",
  "Aktivitet og trening",
  "Jakt",
  "Friluft",
  "Oppdrett",
  "Konkurranser og utstillinger",
  "Terapi",
  "Tjenestehund",
  "Trygghet og beskyttelse",
  "Annet?",
];

interface RouteParams {
  userId: string;
}

const SendApplication: React.FC = () => {
  const history = useHistory();
  const { userId } = useParams<RouteParams>();
  const { user: authUser, isAuthenticated } = useAuth0();
  const { data: userData } = useGetUserByEmailQuery({
    email: authUser?.email ?? "",
  });
  const currentUser = userData?.getUserByEmail.id;
  const id = userId ? parseInt(userId) : currentUser ? currentUser : -1;
  const { data: user } = useGetUserQuery({ id });
  const ads = user?.getUser.ads;
  const { mutate: sendApplication } = useCreateApplicationMutation({
    onSuccess: () => {
      history.push("/soknad-sendt");
    },
  });
  const [step, setStep] = useState(0);
  const [breed, setBreed] = useState<string>();
  const [selectedAd, setSelectedAd] = useState<DogAd>();
  const [reasoning, setReasoning] = useState<string>();
  const [motivation, setMotivations] = useState<string[]>([]);
  const [otherMotivation, setOtherMotivation] = useState<string>();
  const [experience, setExperience] = useState<boolean>();
  const [experienceDetails, setExperienceDetails] = useState<string>();

  useEffect(() => {
    if (!isAuthenticated) {
      history.push("/");
    }
  }, [isAuthenticated]);

  return (
    <div className={css.Application}>
      <div
        className={css.backButton}
        onClick={() => {
          step !== 0 ? setStep(step - 1) : undefined;
        }}
      >{`< Tilbake`}</div>
      {step === 0 && (
        <div className={css.intro}>
          <img
            src="/images/desktop-illustration.svg"
            alt="Søker illustrasjon"
          />
          <p>
            Vær oppmerksom på at selv om søknaden din blir godkjent, kan det
            hende at du ikke får kjøpe en valp umiddelbart, men blir plassert på
            en venteliste hos oppdretteren.
          </p>
          <p>
            Du vil måtte kommunisere med oppdretteren og bli enige om å
            reservere en valp før en bekreftet reservasjon kan gjøres.
          </p>
          <Button label="Gå videre" onClick={() => setStep(step + 1)} />
        </div>
      )}
      {step === 1 && (
        <div className={css.breeds}>
          <h2>Hvilket kull ønsker du å søke på?</h2>
          <div className={css.breedGrid}>
            {ads?.filter((ad) => ad.adType !== "DELETED").length === 0 ? (
              <p>
                Denne oppdretteren har ikke noen kull ute og kan derfor ikke
                motta søknader
              </p>
            ) : undefined}
            {ads
              ?.filter((ad) => ad.adType !== "DELETED")
              .map((ad) => (
                <div
                  key={breed}
                  className={`${css.breedCard} ${
                    selectedAd === (ad as DogAd) ? css.selectedCard : undefined
                  }`}
                  onClick={() => {
                    setSelectedAd(ad as DogAd);
                    setBreed(ad.breed);
                  }}
                >
                  <p>{ad.title}</p>
                  <img
                    src={ad.adImage ?? "/images/placeholder-dog.png"}
                    alt="Annonsebilde"
                  />
                </div>
              ))}
          </div>
          <Button
            label="Gå videre"
            onClick={() => selectedAd !== undefined && setStep(step + 1)}
          />
        </div>
      )}
      {step === 2 && (
        <div className={css.reasoning}>
          <h2>Hvorfor ønsker du akkurat denne rasen?</h2>
          <TextArea
            value={reasoning ?? ""}
            onChange={(text) => setReasoning(text)}
            placeholder="Skriv her..."
            rows={20}
          />
          <Button
            label="Gå videre"
            onClick={() =>
              reasoning && reasoning.trim().length > 0 && setStep(step + 1)
            }
          />
        </div>
      )}
      {step === 3 && (
        <div className={css.motivation}>
          <h2>Hva er motivasjonen din til å skaffe deg hund?</h2>
          <div className={css.motivationGrid}>
            {motivationList.map((item) => (
              <div
                key={item}
                className={`${css.motivationItem} ${
                  motivation?.findIndex((a) => a === item) !== -1
                    ? css.selectedCard
                    : undefined
                }`}
                onClick={() => {
                  motivation?.findIndex((a) => a === item) === -1
                    ? setMotivations([...motivation, item])
                    : setMotivations(
                        motivation?.filter((a) => a !== item) ?? []
                      );
                }}
              >
                {item}
              </div>
            ))}
          </div>
          {motivation?.findIndex((item) => item === "Annet?") !== -1 && (
            <TextArea
              value={otherMotivation ?? ""}
              onChange={(text) => setOtherMotivation(text)}
              placeholder="Skriv her..."
              rows={10}
            />
          )}
          <Button
            label="Gå videre"
            onClick={() => motivation.length > 0 && setStep(step + 1)}
          />
        </div>
      )}
      {step === 4 && (
        <div className={css.experience}>
          <h2>Har du erfaring med hund?</h2>
          <div className={css.experienceGrid}>
            <div
              className={`${css.experienceItem} ${
                experience === true ? css.selectedCard : undefined
              }`}
              onClick={() => setExperience(true)}
            >
              Ja
            </div>
            <div
              className={`${css.experienceItem} ${
                experience === false ? css.selectedCard : undefined
              }`}
              onClick={() => setExperience(false)}
            >
              Nei
            </div>
          </div>
          <Button
            label="Gå videre"
            onClick={() => experience !== undefined && setStep(step + 1)}
          />
        </div>
      )}
      {step === 5 && (
        <div className={css.experience}>
          <h2>Fortell om deg selv og din erfaring med hund.</h2>
          <p>
            Gjør det enklere for oppdretteren å bli kjent med deg som potensiell
            hundeeier! Del litt om din bakgrunn, din nåværende situasjon, og
            dine forventninger som hundeeier. Jo mer du deler, desto bedre kan
            oppdretteren vurdere din egnethet og finne en hund som passer godt
            til deg og ditt hjem.
          </p>
          <TextArea
            value={experienceDetails ?? ""}
            onChange={(text) => setExperienceDetails(text)}
            placeholder="Skriv her..."
            rows={20}
          />
          <Button label="Gå videre" onClick={() => setStep(step + 1)} />
        </div>
      )}
      {step === 6 && (
        <div className={css.overview}>
          <div className={css.header}>
            <h2>Send søknad til {user?.getUser.kennel?.name}</h2>
            <p className={css.helpText}>
              Du er på vei til å sende en søknad til oppdretter. Svar ærlig, og
              spesifiser om det er en spesiell valp du ønsker.
            </p>
          </div>
          <div className={css.content}>
            <h2>Hvilket kull ønsker du å søke på?</h2>
            <div className={css.breedCard}>
              <p>{selectedAd?.title}</p>
              <img
                src={selectedAd?.adImage ?? "/images/placeholder-dog.png"}
                alt="Annonsebilde"
              />
            </div>
            <h2>Har du erfaring med hund?</h2>
            {experience ? (
              <div className={css.experienceItem}>Ja</div>
            ) : (
              <div className={css.experienceItem}>Nei</div>
            )}
            <h2>Hvorfor ønsker du akkurat denne rasen?</h2>
            <p>{reasoning}</p>
            <h2>Hva er motivasjonen din til å skaffe deg hund?</h2>
            <p>{otherMotivation}</p>
            <div className={css.experienceGrid}>
              {motivation.map((item) => (
                <div key={item} className={css.motivationItem}>
                  {item}
                </div>
              ))}
            </div>
            <h2>Fortell om deg selv</h2>
            {experienceDetails}
            <div>
              <Button label="Tilbake" onClick={() => setStep(step - 1)} />
              <Button
                label="Send inn"
                onClick={() => {
                  if (
                    currentUser &&
                    selectedAd &&
                    breed &&
                    experienceDetails &&
                    motivation &&
                    reasoning
                  ) {
                    sendApplication({
                      adId: selectedAd.id,
                      breed,
                      experienceDetail: experienceDetails,
                      otherMotivation,
                      motivation,
                      hasExperience: !!experience,
                      reasoning,
                    });
                  }
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SendApplication;
