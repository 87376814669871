/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { DogAd, useGetAllAdsQuery } from "../api/graphql.gen";
import Button from "../components/Button";
import Footer from "../components/Footer";
import AdCard from "../components/AdCard";
import css from "../styles/Home.module.scss";
import imageUrlBuilder from "@sanity/image-url";
import { useIsMobile } from "../hooks/useIsMobile";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { client } from "../index";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import { isBefore } from "date-fns";
import ArticleCard from "../components/ArticleCard";
import { Link } from "react-router-dom";
import { Community } from "../components/Community";
import { Collapse, Paper } from "@mui/material";
import Fade from "react-reveal/Fade";
import { Section, getPosts } from "../utils/sanity-common";
import { PortableText } from "@portabletext/react";

const Home: React.FC = () => {
  const history = useHistory();
  const [aboutPondSections, setAboutPondSections] = useState<Section[]>([]);
  const [pondVisible, setPondVisible] = useState<boolean[]>([]);
  const limitedSections = aboutPondSections.slice(0, 6);

  const { loginWithRedirect } = useAuth0();
  const { data: allAds } = useGetAllAdsQuery();
  const [articles, setArticles] = useState<any>();
  const [sortedArticles, setSortedArticles] = useState<any>();
  const [randomAds, setRandomAds] = useState<DogAd[]>();
  const { isMobile } = useIsMobile(1024);
  const readMoreRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    window.analytics.page("Forside");

    getFAQ();

    client
      .fetch(`*[_type == "articles"] | order(date desc)`)
      .then((sanityArticles) => setArticles(sanityArticles));
  }, []);

  useEffect(() => {
    if (articles) {
      const publishedArticles = articles.filter((article: any) =>
        isBefore(new Date(article.date), new Date())
      );
      setSortedArticles(publishedArticles.slice(0, 3));
    }
  }, [articles]);

  // Image builder for sanity client: Generates url baset on image asset
  const builder = imageUrlBuilder(client);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function urlFor(source: any) {
    return builder.image(source.asset);
  }

  useEffect(() => {
    const ads = allAds?.dogAds
      .filter((item) => item.adType === "AVAILABLE")
      .sort(() => 0.5 - Math.random())
      .slice(0, 3);
    setRandomAds(ads as DogAd[]);
  }, [allAds]);

  useEffect(() => {
    const script = document.createElement("script");

    script.innerHTML = `
    var BotStar = {
      appId: "s100789a0-cd84-11ed-917e-abdbde2d37a6",
      mode: "popup",
    };
    !(function (t, a) {
      var e = function () {
        (e.q = e.q || []).push(arguments);
      };
      (e.q = e.q || []), (t.BotStarApi = e);
      !(function () {
        var t = a.createElement("script");
        (t.type = "text/javascript"),
          (t.async = 1),
          (t.src = "https://widget.botstar.com/static/js/widget.js");
        var e = a.getElementsByTagName("script")[0];
        e.parentNode.insertBefore(t, e);
      })();
    })(window, document);`;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [window.location]);

  const executeScroll = (ref: React.RefObject<HTMLDivElement>) => {
    ref.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  };

  async function getFAQ() {
    const pondSections = await getPosts("faq-om-pond");
    setAboutPondSections(pondSections);
  }

  // Bruk useEffect for å håndtere endringer i aboutPondSections og aboutAccountSections
  useEffect(() => {
    setPondVisible(Array(aboutPondSections.length).fill(false));
  }, [aboutPondSections]);

  return (
    <div className={css.Home}>
      <div
        className={css.banner}
        style={{
          background: isMobile
            ? `url("/images/home/rabbits-desktop.png")`
            : `url("/images/home/banner.png")`,
          backgroundSize: "cover",
        }}
      >
        <Fade bottom delay={100}>
          <div className={css.card}>
            <div>
              <h1>Enkel vei til hundeglede</h1>
              <p>
                POND gir oppdrettere en plattform for å vise frem og
                administrere sin kennel, samtidig som valpekjøpere enkelt finner
                sin perfekte hund. Gjør avlshverdagen enklere og valpejakten
                sømløs med POND. Bli en del av POND i dag!
              </p>
            </div>
            <Button
              label="Les mer"
              skin="light"
              onClick={() => executeScroll(readMoreRef)}
            />
            <Button
              label="Kom i gang"
              onClick={() => {
                window.analytics.track("Kom i gang - Forside");
                loginWithRedirect({
                  openUrl: async (url: string) => window.location.replace(url),
                });
              }}
            />
          </div>
        </Fade>
      </div>
      <div className={css.osloDogShow}>
        <div className={css.osloDogShowTitle}>
          <h2>Valpe TV er her!</h2>
          <p>
            I forkant av{" "}
            <u>
              <a href="https://www.oslodogshow.no">
                Oslo Dog Show og Norges største valpeshow på Lillestrøm 20.–22.
                september
              </a>
            </u>{" "}
            kan du følge tispen Ragna og hennes fem små nurk i sanntid. Opplev
            hvordan valpene vokser og utvikler seg i valpekassen, minutt for
            minutt!
          </p>
        </div>
        <iframe
          src="https://ljsp.lwcdn.com/api/video/embed.jsp?id=623c1534-ecd3-4f4d-a492-150f18576b91&pi=36c4b33b-91a3-43f3-92cf-34c85c11d8b5"
          title="Video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        />
        <Button
          label="Les mer om kullet"
          onClick={() => history.push("/annonse/357")}
        />
        <div className={css.showPartnersContainer}>
          <p>I samarbeid med</p>
          <div className={css.showPartnersContainerImages}>
            <img
              src="/images/partners/oslodogshowlogo.png"
              alt="Oslodogshow logo"
              onClick={() =>
                (window.location.href = "https://www.oslodogshow.no//")
              }
            />
            <img
              src="/images/partners/romerikebladlogo.png"
              alt="RomerikeBlad logo"
              onClick={() => (window.location.href = "https://www.rb.no//")}
            />
          </div>
        </div>
      </div>
      <div className={css.becomeBreeder}>
        <h2>Gjør valpeletingen enklere</h2>
        <div className={css.cardContainer}>
          <Fade bottom>
            <Paper>
              <Link
                className={css.card}
                onClick={() => {
                  window.analytics.track("Raser kort - Forside");
                }}
                to="/hunderaser"
              >
                <img src="/images/home/breeds.png" alt="Kort bilde" />
                <p>Utforsk raser</p>
              </Link>
            </Paper>
          </Fade>
          <Fade bottom delay={100}>
            <Paper>
              <Link
                className={css.card}
                onClick={() => {
                  window.analytics.track("Annonser kort - Forside");
                }}
                to="/valper-til-salgs"
              >
                <img src="/images/home/puppies.png" alt="Kort bilde" />
                <p>Valper til salgs</p>
              </Link>
            </Paper>
          </Fade>
          <Fade bottom delay={200}>
            <Paper>
              <Link
                className={css.card}
                onClick={() => {
                  window.analytics.track("Oppdrettere kort - Forside");
                }}
                to="/oppdrettere"
              >
                <img src="/images/home/breeders.png" alt="Kort bilde" />
                <p>Se alle oppdrettere</p>
              </Link>
            </Paper>
          </Fade>
        </div>
      </div>
      <div className={css.partnersContainer}>
        <h3>Samarbeidspartner med</h3>
        <div className={css.partners}>
          <div>
            <a
              href="https://www.agria.no/"
              target="_blank"
              rel="noreferrer"
              onClick={() => window.analytics.track("Agria redirect")}
            >
              <img
                src="https://cdn.sanity.io/images/evk9xyav/production/796acbf384a595eae3ea2c2e28dcb3905150c9e2-257x112.png?w=2000&fit=max&auto=format&dpr=2"
                alt="Agria logo"
              />
            </a>
          </div>
          <div>
            <a
              href="https://www.innovasjonnorge.no/"
              target="_blank"
              rel="noreferrer"
              onClick={() =>
                window.analytics.track("Innovasjon Norge redirect")
              }
            >
              <img
                src="/images/partners/InnovasjonNorgeLogo.svg"
                alt="Innovasjon Norge logo"
              />
            </a>
          </div>
        </div>
      </div>
      <div className={css.slides}>
        <Swiper
          slidesPerView={1}
          spaceBetween={0}
          style={{ paddingBottom: 60 }}
          className="mySwiper"
          navigation={!isMobile}
          autoplay={true}
          speed={1000}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination, Navigation, Autoplay]}
        >
          <SwiperSlide>
            <div className={css.swiperCard}>
              <img
                src="/images/home/breed-profile.png"
                alt="Bilde av raseoversikt"
              />
              <div>
                <h2>Finn riktig rase</h2>
                <p>
                  Vårt rasebibliotek gir inngående beskrivelser av behov,
                  personlighet, aktivitetsnivå, helse og mer.
                </p>
                <Button
                  label="Gå til rasebibliotek"
                  onClick={() => history.push("/hunderaser")}
                />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={css.swiperCard}>
              <img
                src="/images/home/breeder-profile.png"
                alt="Bilde av raseoversikt"
              />
              <div>
                <h2>Oppdretterprofiler</h2>
                <p>
                  Få relevant informasjon om oppdrettere, erfaring og deres
                  planer for valpekull i fremtiden.
                </p>
                <Button
                  label="Se alle oppdrettere"
                  onClick={() => history.push("/oppdrettere")}
                />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={css.swiperCard}>
              <img
                src="/images/home/applications.png"
                alt="Bilde av raseoversikt"
              />
              <div>
                <h2>Søknader</h2>
                <p>
                  Ved å kreve søknad veileder man kommende hundeeiere og
                  filtrerer vekk impulsbeslutninger. Vi ønsker å bidra til mer
                  gjennomtenkte valg og bedre matcher mellom hund, eier og
                  oppdretter.
                </p>
                <Button
                  label="Lag POND-bruker"
                  onClick={() => loginWithRedirect()}
                />
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <div className={css.community}>
        <Fade bottom>
          <h2>Vårt felleskap</h2>
          <Community />
          <Button label="Om POND" onClick={() => history.push("/om-oss")} />
        </Fade>
      </div>
      <div className={css.whySection} ref={readMoreRef}>
        <div className={css.content}>
          <h2>Skreddersydd for hundeglade hjerter</h2>
          {!isMobile && (
            <p>
              POND gir deg en enkel vei til hundeglede – administrer
              kennelprofilen, presenter avlshundene, og få mer tid til å nyte
              kjærligheten til dine firbeinte venner. Valpesøkere får en
              omfattende oversikt over hunderaser, muligheten til å sende inn
              valpesøknader, favorisere ønskede raser, og motta varsler om
              kommende kull.
            </p>
          )}
          {!isMobile && (
            <Button
              label="Prøv POND gratis"
              onClick={() => {
                loginWithRedirect({
                  openUrl: async (url: string) => window.location.replace(url),
                });
              }}
            />
          )}
          <div className={css.cardContainer}>
            <div className={css.breederCard}>
              <h4>For oppdretter</h4>
              <div>
                <p>
                  <img src="/images/home/darkCheck.svg" alt="Checkmark" />
                  Kennelprofil
                </p>
                <p>
                  <img src="/images/home/darkCheck.svg" alt="Checkmark" />
                  Vis frem avlshunder
                </p>
                <p>
                  <img src="/images/home/darkCheck.svg" alt="Checkmark" />
                  Planlagt/forventet kull
                </p>
                <p>
                  <img src="/images/home/darkCheck.svg" alt="Checkmark" />
                  Motta valpesøknader
                </p>
                <p>
                  <img src="/images/home/darkCheck.svg" alt="Checkmark" />
                  Bli mer synlig
                </p>
                <p>
                  <img src="/images/home/darkCheck.svg" alt="Checkmark" />
                  40% hos QualiPet
                </p>
              </div>
            </div>
            <div className={css.buyerCard}>
              <h4>For kjøper</h4>
              <div>
                <p>
                  <img src="/images/home/lightCheck.svg" alt="Checkmark" />
                  Oversikt over hunderaser
                </p>
                <p>
                  <img src="/images/home/lightCheck.svg" alt="Checkmark" />
                  Oppdretteroversikt
                </p>
                <p>
                  <img src="/images/home/lightCheck.svg" alt="Checkmark" />
                  Favoriser raser
                </p>
                <p>
                  <img src="/images/home/lightCheck.svg" alt="Checkmark" />
                  Få varsel ved kull
                </p>
                <p>
                  <img src="/images/home/lightCheck.svg" alt="Checkmark" />
                  Digital valpesøknad
                </p>
              </div>
            </div>
          </div>
          {isMobile && (
            <Button
              label="Prøv POND gratis"
              onClick={() => {
                loginWithRedirect({
                  openUrl: async (url: string) => window.location.replace(url),
                });
              }}
            />
          )}
        </div>
      </div>
      <div className={css.testimonials}>
        <Fade bottom>
          <div className={css.content}>
            {!isMobile && <h2>Hva våre brukere sier...</h2>}
            <Swiper
              slidesPerView={isMobile ? 1 : 4}
              spaceBetween={16}
              style={{ paddingBottom: 50 }}
              autoplay={true}
              speed={1000}
              className="mySwiper"
              pagination={{
                clickable: true,
              }}
              modules={[Pagination, Autoplay]}
            >
              <SwiperSlide className={css.card}>
                <div className={css.quote}>
                  “Det er vanskelig å navigere i jungelen av forskjellige
                  valpekjøpere og alt som kan lette den jobben er et pluss.”
                </div>
                <div className={css.person}>
                  Anette, oppdretter av Norsk Lundehund
                </div>
              </SwiperSlide>
              <SwiperSlide className={css.card}>
                <div className={css.quote}>
                  “Veldig spennende og absolutt et verdifullt bidrag til å
                  fremme seriøse oppdrettere og gjøre det tryggere for
                  fremtidige hundeeiere å finne valper. “
                </div>
                <div className={css.person}>
                  Hilde Jensen, oppdretter av Whippet
                </div>
              </SwiperSlide>
              <SwiperSlide className={css.card}>
                <div className={css.quote}>
                  “Spennende konsept! Fint at man har mulighet til å bli litt
                  mer kjent med både oppdretter og hundene.”
                </div>
                <div className={css.person}>
                  Raymond, oppdretter av Dachshund & Haldenstøver
                </div>
              </SwiperSlide>
              <SwiperSlide className={css.card}>
                <div className={css.quote}>
                  “Jeg håper dette overtar ALLE former for kjøp og salg av
                  hunder. Sikkerhet for alle parter er noe som mangler i dagens
                  hundemarked. Takk for at dere gjør dette!”
                </div>
                <div className={css.person}>Hundeeier, Alaskan Malamute.</div>
              </SwiperSlide>
              <SwiperSlide className={css.card}>
                <div className={css.quote}>
                  “Det er definitivt et marked for en toveis formidling, og jeg
                  håper det blir et kvalitetsstempel for oss oppdrettere å være
                  en del av POND. Trygt å avle - trygt å kjøpe”
                </div>
                <div className={css.person}>
                  Monica, oppdretter av Welsh Springer Spaniel i 17 år.
                </div>
              </SwiperSlide>
              <SwiperSlide className={css.card}>
                <div className={css.quote}>
                  “Spennende konsept som mange sikkert kommer til å bruke!
                  Spesielt de som driver med seriøs avl”
                </div>
                <div className={css.person}>
                  Birgitte, oppdretter av Labrador.
                </div>
              </SwiperSlide>
              <SwiperSlide className={css.card}>
                <div className={css.quote}>
                  “Genial idé som forhåpentligvis fører til at flere finner
                  riktig hund for sin livsstil!”
                </div>
                <div className={css.person}>
                  Bitten, eier av Jack Russel Terrier.
                </div>
              </SwiperSlide>
              <SwiperSlide className={css.card}>
                <div className={css.quote}>
                  “For oss oppdrettere er POND en fin plattform for å gi mer
                  konkret informasjon om oss selv og våre hunder. For mange kan
                  Pond fungere som en erstatning for en hjemmeside.”
                </div>
                <div className={css.person}>
                  Kjersti Olsen, oppdretter av Berner Sennenhund i 28 år.
                </div>
              </SwiperSlide>
              <SwiperSlide className={css.card}>
                <div className={css.quote}>
                  “De er uavhengige av interessegrupper samtidig som de
                  opprettholder tillit og engasjement for etisk hundevelferd. De
                  er dristige, og jeg tror på dem.”
                </div>
                <div className={css.person}>
                  Johanne, oppdretter av Sibirsk husky i 25 år.
                </div>
              </SwiperSlide>
              <SwiperSlide className={css.card}></SwiperSlide>
            </Swiper>
          </div>
          <Button label="Bli med i fellesskapet!" onClick={loginWithRedirect} />
        </Fade>
      </div>
      <div className={css.articles}>
        <Fade bottom>
          <h2>Nyeste artikler</h2>
          <div className={css.content}>
            {isMobile ? (
              <Swiper
                slidesPerView={1}
                style={{ paddingBottom: 60 }}
                spaceBetween={15}
                className="mySwiper"
                autoplay={true}
                speed={1000}
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination, Autoplay]}
              >
                {sortedArticles?.map((article: any) => (
                  <SwiperSlide
                    key={article.title}
                    className={css.articleSwiper}
                  >
                    <ArticleCard
                      title={article.title}
                      url={article.slug.current}
                      articleType={article.articleType}
                      img={urlFor(article.coverImage).url()}
                      breed={article.breed}
                      date={new Date(article.date)}
                      mobile={true}
                      className={css.mobileArticleCard}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : (
              <div className={css.articleGrid}>
                {sortedArticles?.map((article: any) => (
                  <ArticleCard
                    key={article.title}
                    title={article.title}
                    url={article.slug.current}
                    articleType={article.articleType}
                    img={urlFor(article.coverImage).url()}
                    breed={article.breed}
                    date={new Date(article.date)}
                    mobile={true}
                  />
                ))}
              </div>
            )}
          </div>
        </Fade>
      </div>
      <div className={css.ads}>
        <h2>Valper til salgs</h2>
        <div className={css.content}>
          <Fade bottom>
            {isMobile ? (
              <Swiper
                slidesPerView={1}
                spaceBetween={15}
                style={{ paddingBottom: 60 }}
                className="mySwiper"
                autoplay={true}
                speed={1000}
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination, Autoplay]}
              >
                {randomAds?.map((ad) => (
                  <SwiperSlide key={ad.id}>
                    <AdCard
                      isMobile={true}
                      status={ad.adType}
                      imgSrc={
                        ad.malePhotos[0] ??
                        ad.adImage ??
                        "/images/placeholder-dog.png"
                      }
                      className={css.gridItem}
                      imgAlt="Annonsebilde"
                      breedName={ad.breed}
                      breederName={ad.user.kennel?.name ?? ""}
                      price={ad.price ?? 0}
                      totalDogsAvailable={
                        ad.numberOfPuppies ??
                        ad.males.length + ad.females.length
                      }
                      city={ad.user?.address?.city ?? ""}
                      to={`/annonse/${ad.id}`}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : (
              <div className={css.adGrid}>
                {randomAds?.map((ad) => (
                  <AdCard
                    key={ad.id}
                    status={ad.adType}
                    imgSrc={
                      ad.malePhotos[0] ??
                      ad.adImage ??
                      "/images/placeholder-dog.png"
                    }
                    className={css.gridItem}
                    imgAlt="Annonsebilde"
                    breedName={ad.breed}
                    breederName={ad.user.kennel?.name ?? ""}
                    price={ad.price ?? 0}
                    totalDogsAvailable={
                      ad.numberOfPuppies ?? ad.males.length + ad.females.length
                    }
                    city={ad.user?.address?.city ?? ""}
                    to={`/annonse/${ad.id}`}
                  />
                ))}
              </div>
            )}
          </Fade>
        </div>
        <Button
          label="Se valper til salgs"
          skin="lightGreen"
          onClick={() => history.push("/annonser")}
        />
      </div>
      <div className={css.faq}>
        <div className={css.url}>
          <h3>Er det noe du lurer på?</h3>
          <div style={{ marginBottom: "1rem" }}>
            {limitedSections?.map((section, index) => (
              <div key={section?.heading} className={css.questionContainer}>
                <div
                  className={css.dropDown}
                  onClick={() => {
                    const arr: boolean[] = [];
                    pondVisible.map((value, i) => {
                      index === i ? arr.push(!value) : arr.push(value);
                    });
                    setPondVisible(arr);
                  }}
                >
                  <h4 className={css.question}>{section.heading}</h4>
                  <img
                    src={
                      pondVisible[index]
                        ? "/images/icons/arrowup.svg"
                        : "/images/icons/arrowdown.svg"
                    }
                    alt="Åpne ikon"
                  />
                </div>
                <div
                  onClick={() => {
                    const arr: boolean[] = [];
                    pondVisible.map((value, i) => {
                      index === i ? arr.push(!value) : arr.push(value);
                    });
                    setPondVisible(arr);
                  }}
                >
                  <Collapse in={pondVisible[index]} timeout="auto">
                    <p className={css.answer}>
                      <PortableText value={section.blockArray} />
                    </p>
                  </Collapse>
                </div>
              </div>
            ))}
          </div>
          <Button
            onClick={() => history.push("/faq")}
            label="Se alle"
            skin="dark"
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
